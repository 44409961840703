import { Auth } from "aws-amplify";
//ログアウト時の実装
export const signOut = async () => {
  try {
    await Auth.signOut();

    // localStorageから「jws-」で始まるデータを削除する。
    const removeKeys = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (/^jws-/.test(key)) {
        removeKeys.push(key);
      }
    }
    removeKeys.forEach((key) => localStorage.removeItem(key));

    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};
