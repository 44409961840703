import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLoginInfo, selectProfile } from "../features/Profile/profileSlice";
import { signOut } from "../features/awsAuth";

export const Header = () => {
  const profile = useSelector(selectProfile);
  if (!profile.isLogin) {
    // 一回だけユーザ情報を取得させる
    console.log("call getLoginInfo()");
    console.log(profile);
    const dispatch = useDispatch();
    dispatch(getLoginInfo());
  }

  return (
    <header id="global-head">
      <h1 id="brand-logo">JWSポータル</h1>
      <button className="btn-border5" onClick={signOut}>
        ログアウト
      </button>
    </header>
  );
};
