import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import { initJwsCommonApi, getPersonsByEmail } from "../../api/jws-common-api";

const USER_INFO_LOCAL_STORAGE_KEY = "jws-common-app-user-info";
const initialState = {
  isLogin: false,
  email: null,
  user_name: "",
  systems: [],
};

export const getLoginInfo = createAsyncThunk(
  "profile/getLoginInfo",
  async () => {
    // Amplify（Cognito）の認証情報からメールアドレスを取得し、
    // メールアドレスを基に共通マスタAPIを実行し、情報を取得する
    console.log("profile/getLoginInfo");
    const { attributes, signInUserSession } =
      await Auth.currentAuthenticatedUser();
    console.log(attributes);
    const email = attributes.email;
    initJwsCommonApi(signInUserSession.idToken.jwtToken); // AWS CognitoのトークンをAPI呼び出し用に渡す

    /* 1. (参考) axiosを使ってAPIを実行する例 --- ここから ---*/
    // const client = axios.create({
    //   baseURL: "http://localhost:8081",
    // });

    /* 1-1. (参考) axiosのレスポンスを処理する例。react-reduxにおいてはこの記載方法はしない。
     */
    // await client
    //   .get(`/persons?email=${email}`)
    //   .then((response) => {
    //     console.log("success", response);
    //     data = response.data[0];
    //   })
    //   .catch((err) => {
    //     console.log("error", err);
    //   });

    /* 1-2. (参考) axiosのレスポンスを処理する例。axiosを使う場合はこのように利用し、エラーの場合はthunkで判断する。
     */
    // const response = await client.get(`/persons?email=${email}`);
    // return response.data[0];

    /* 1. (参考) axiosを使ってAPIを実行する例 --- ここまで ---*/

    // 共通マスタAPI実行
    // const response = await API.get("common-api", `/persons?email=${email}`);

    // localStoragにデータがあればそれを利用する
    console.log("test1");
    const userInfoFromLocalStorage = getUserInfoFromLocalStorage();
    if (userInfoFromLocalStorage !== null) {
      return userInfoFromLocalStorage;
    }
    console.log("test2");

    const response = await getPersonsByEmail(email);
    const person = response[0];
    // APIの結果は配列であるため、最初の要素を返却する
    const userInfo = {
      isLogin: true,
      email: person.email1,
      userId: person.id,
      user_name: person.name,
      deptId: person.dept.id,
      systems: person.system == null ? [] : person.system,
    };
    return userInfo;
  }
);

/**
 * localStorageから「userInfo」を取得する
 * @returns userInfo
 */
const getUserInfoFromLocalStorage = () => {
  const userInfoStr = localStorage.getItem(USER_INFO_LOCAL_STORAGE_KEY);
  if (userInfoStr === null) {
    return null;
  }
  return JSON.parse(userInfoStr);
};

/**
 * localStorageにuserInfoオブジェクトを格納する
 * @param {obj} userInfo
 */
const setUserInfoToLocalStorage = (userInfo) => {
  localStorage.setItem(USER_INFO_LOCAL_STORAGE_KEY, JSON.stringify(userInfo));
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  extraReducers: (builder) => {
    builder
      // .addCase(getLoginInfo.pending, (state) => {
      //   console.log("before API " + state);
      // })
      .addCase(getLoginInfo.fulfilled, (state, action) => {
        console.log("getLoginInfo.fulfilled");
        console.log(state);
        console.log(action);
        state.isLogin = action.payload.isLogin;
        state.email = action.payload.email;
        state.userId = action.payload.userId;
        state.user_name = action.payload.user_name;
        state.systems = action.payload.systems;
        state.deptId = action.payload.deptId;
        const userInfo = state;
        setUserInfoToLocalStorage(userInfo);
        return state;
      })
      .addCase(getLoginInfo.rejected, (state) => {
        console.log("getLoginInfo.rejected");
        // TODO: エラーの場合は、システムエラー画面を表示する対応が必要
        console.log(state);
      });
  },
});

export const selectProfile = (state) => state.profile;

export default profileSlice.reducer;
