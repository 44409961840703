import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store";

import { Amplify, Auth } from "aws-amplify"; // amplifyライブラリのインポート
import awsExports from "./aws-exports"; // バックエンドの設定ファイルをインポート
import { Authenticator, View, Image, useTheme } from "@aws-amplify/ui-react"; // 認証UIコンポーネントをインポート
import "@aws-amplify/ui-react/styles.css"; // UIコンポーネントのcssをインポート
import { Header } from "./components/Header";
import { Main } from "./components/Main";

Amplify.configure(awsExports);
Amplify.configure({
  API: {
    endpoints: [
      {
        name: "common-api",
        endpoint: process.env.REACT_APP_COMMON_API_ENDPOINT,
        custom_header: async () => {
          // return { Authorization : 'token' }
          // Alternatively, with Cognito User Pools use this:
          // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

const components = {
  // Amplifyのコンポーネントをカスタマイズする
  Header() {
    // Amplifyのログイン画面のヘッダーをカスタマイズ
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="JWS logo"
          src="img/logo.jpg"
          maxWidth="150px"
          maxHeight="150px"
        />
      </View>
    );
  },
};

// const setMessageEvent = () => {
//   // ここにアクセス元のドメインを指定する
//   // 例）TACSのドメインや、MARSのドメイン
//   const origins = ["http://localhost:3001"];

//   window.addEventListener("message", function (event) {
//     // 送信元が指定のオリジンと一致していれば処理を行う
//     if (origins.indexOf(event.origin) >= 0) {
//       const message = event.data;

//       // メッセージが'get'ならlocalstorageの値を返す
//       if (message === "get") {
//         // localStorageのキーペアを配列にして返却する
//         const values = [];
//         for (let i = 0; i < localStorage.length; i++) {
//           const key = localStorage.key(i);
//           const value = localStorage.getItem(key);
//           values.push({ key: key, value: value });
//         }
//         const message_value = JSON.stringify(values);
//         event.source.postMessage(message_value, event.origin);
//       } else if (message === "delete") {
//         // メッセージが'delete'ならlocalStorageをクリアする。
//         localStorage.clear();
//       }
//     }
//   });
// };

function App() {
  // setMessageEvent();

  return (
    <React.StrictMode>
      <Authenticator components={components}>
        {() => (
          <Provider store={store}>
            <Header />
            <Main />
          </Provider>
        )}
      </Authenticator>
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
