import React from "react";
import { useSelector } from "react-redux";
import { selectProfile } from "../features/Profile/profileSlice";

export const Main = () => {
  // redux-storeからログインユーザの情報を取得し、アクセス可能なシステムの一覧を表示する
  const profile = useSelector(selectProfile);
  const systems = profile.systems;

  return (
    <main id="main">
      <ul className="ul-menu">
        {systems.map((item) => {
          return (
            <li key={item.id}>
              <a href={item.url}>{item.systemName}</a>
            </li>
          );
        })}
      </ul>
    </main>
  );
};
